// 审核项目列表
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      :color="projectStatusColor"
      icon="mdi-clipboard-text"
      :title="projectStatusTitle + '（共'+totalElement+'个）'"
      class="px-5 py-3 mt-12"
    >
      <v-row class="mt-2">
        <v-col
          class="pb-0"
        >
          <v-text-field
            v-model="parentName"
            label="项目名称"
            solo
            dense
            clearable
          />
        </v-col>
        <v-col
          md="1"
        >
          <v-btn
            elevation="1"
            class="ma-1"
            color="blue mt-0"
            @click="getProjectList"
          >
            <v-icon left>
              fa4 fa-search
            </v-icon>
            搜索
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th
              width="80"
            >
              序号
            </th>
            <th>项目名称</th>
            <th width="200">
              产业类别
            </th>
            <th width="200">
              创建时间
            </th>
            <th width="200">
              提报用户
            </th>
            <th width="100">
              审核状态
            </th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
          >
            <td>{{ (page) * pageSize + index + 1 }}</td>
            <td>
              <router-link
                class="indigo--text"
                :to="'/report-details/' + project.formId + '/' + project.id"
              >
                {{ project.name }}
              </router-link>
            </td>

            <td>{{ project.data.industry }}</td>
            <td>{{ project.createdAt | dateformat1 }}</td>
            <td>{{ project.nickname }}</td>
            <td>
              {{ statusTitle }}
            </td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                @click="examine(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                审核
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-model="nowPage"
        :length="pageNum"
        total-visible="10"
        color="indigo darken-4"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="pageChange"
      />
    </base-material-card>
  </v-container>
</template>
<script>
  export default {
    components: {
      search: () => import('../../components/base/Search'),
    },
    data: () => ({
      searchProjectName: '',
      industryCategory: '',
      projectStatus: 0,
      projectList: [], // 项目数据列表
      page: 0,
      nowPage: 1,
      pageNum: 0,
      pageSize: 15,
      pageLenght: 0,
      projectStatusTitle: '',
      projectStatusColor: '',
      isForm: true,
      totalElement: 0,
      parentName: '',
      statusTitle: '',
    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {
          this.getProjectList()
          this.uploadUI()
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    created () {
      this.uploadUI()
      this.searchData()
    },
    methods: {
      isStatus () {
        if (this.$route.params.status) {
          return this.$route.params.status
        } else {
          return false
        }
      },
      uploadUI () {
        switch (this.$route.query.astatus) {
          case '10':
            this.projectStatusTitle = '已通过项目'
            this.projectStatusColor = 'success'
            this.statusTitle = '通过审核'
            break
          case '0':
            this.projectStatusTitle = '待确认项目'
            this.projectStatusColor = 'blue darken-3'
            this.statusTitle = '正在审核'
            break
          case '-5':
            this.projectStatusTitle = '待完善项目'
            this.projectStatusColor = 'red darken-3'
            this.statusTitle = '待完善'
            break
          case '-10':
            this.projectStatusTitle = '彻底驳回'
            this.projectStatusColor = 'red darken-3'
            this.statusTitle = '待完善'
            break
        }
      },
      // 获取项目列表
      getProjectList () {
        const searchParame = JSON.parse(JSON.stringify(this.$route.query))
        this.parentName && (searchParame.name = this.parentName)
        searchParame.astatus = this.$route.query.astatus ? this.$route.query.astatus : this.$route.query.status
        searchParame.page = this.page
        console.log(searchParame)
        this.$axios.get('/reports/audit2', {
          params: searchParame,
        })
          .then((res) => {
            const resData = res.data.data
            this.projectList = resData.content
            this.pageNum = resData.totalPages
            this.totalElement = resData.totalElements
          })
          .catch((error) => {
            console.log(error)
          })
      },
      examine (index) { // 审核项目
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: this.projectList[index].formId,
            id: this.projectList[index].id,
          },
        })
      },
      pageChange (value) { // 换页按钮
        this.nowPage = value
        this.page = value - 1
        this.getProjectList()
      },
      searchData () {
        console.log('项目被点击了')
        this.getProjectList()
      },
    },
  }
</script>
